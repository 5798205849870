import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Styled from 'styled-components';

import { connect } from 'react-redux';

import { growl } from '../redux/actions/notifications/actionCreators';

// import NavBar from '../components/navBar';
import Header from '../components/header';
import Footer from '../components/footer';
import IE11Notification from '../components/ie11Notification';
import NotificationProvider from '../components/notificationProvider';
import LiveChat from '../components/liveChat';
import Tracker from '../components/tracker';

import usePresence from '../hooks/usePresence';

import config from '../config';

import '../util/normalize.css';
import '../styles/utility-styles.css';

function Layout(props) {
  const [anyOnline] = usePresence();

  const { children, hero, full } = props;
  return (
    <StaticQuery
      query={graphql`
        query SiteMetaDataQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <LayoutWrapper hero={hero} full={full}>
          <Helmet>
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta name="application-name" content="Sci-Tech Carpet Cleaning" />
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
          </Helmet>
          <IE11Notification />
          <Header />
          {hero}
          <div className="main-layout__content-wrapper">
            {typeof children === 'function' ? (
              <Fragment>{children}</Fragment>
            ) : (
              <Fragment>{children}</Fragment>
            )}
          </div>
          <Footer />
          {anyOnline && config.backendActive && <LiveChat />}
          {config.backendActive && <Tracker />}
          <NotificationProvider />
        </LayoutWrapper>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  full: PropTypes.bool,
};

Layout.defaultProps = {
  full: false,
};

const LayoutWrapper = Styled.div`
  font-family: lato, sans-serif;
  font-size: 18px;

  .scroll-animatable {
    opacity: 0;
    transform: translateY(60px);
    transition: all 1.8s ease;
  }

  .scroll-animate {
    opacity: 1;
    transform: none;
    transition: all 1.8s ease;
  }
  
  .main-layout__content-wrapper {
    width: ${({ full }) => (full ? 'auto' : '750px')};
    margin: ${({ hero, full }) => (hero || full ? '0 auto' : '60px auto')};
    padding: 0px 1.0875rem 1.45rem;
    padding-top: 50px;

    h1.underlined, h2.underlined, h3.underlined, h4.underlined {
      &:after {
        display: block;
        content: "";
        border-bottom: 2px solid #E96656;
        width: 150px;
        margin: 10px 0 20px 0;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .main-layout__content-wrapper {
      width: 600px;
    }
  }

  @media screen and (max-width: 700px) {
    .main-layout__content-wrapper {
      width: 540px;
    }
  }

  @media screen and (max-width: 600px) {
    .main-layout__content-wrapper {
      width: 460px;
    }
  }

  @media screen and (max-width: 500px) {
    .main-layout__content-wrapper {
      width: 90%;
    }
  }
`;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ type, message }) => {
      dispatch(growl({ type, message }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
