import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  login,
  loginAnonymously,
  logout,
  tokenExists,
  getEmailToken,
} from '../redux/actions/authentication/actionCreators';

import { growl } from '../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../redux/actions/authorization/actionCreators';

import io from 'socket.io-client';

import MatchMediaCheck from '../util/matchMediaCheck';

import config from '../config';

class Tracker extends Component {
  static propTypes = {
    onGetVisitorId: PropTypes.func,
  };

  componentDidMount() {
    this.trackerSocket = io(`${config.backendServer}/tracker`);
    if (!this.props.user) {
      this.props
        .loginAnonymously()
        .then(() => {
          if (this.props.token) {
            this.establishConnection();
          }
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.establishConnection();
    }
  }

  componentWillUnmount() {}

  establishConnection = () => {
    const matchMedia = MatchMediaCheck('960px');
    this.trackerSocket.emit(
      'tracker.subscribe',
      { token: this.props.token },
      (err, res) => {
        if (err) {
          console.error(err);
        }
      }
    );
    this.trackerSocket.emit(
      'tracker.page.visit',
      {
        user: this.props.user._id,
        location: window.location.pathname,
        browser: this.getBrowser(),
        isMobile: matchMedia.matches(),
      },
      (err, res) => {
        if (err) {
          console.error(err);
        }
      }
    );
  };

  getBrowser = () => {
    const ua = window.navigator.userAgent;
    if (ua.includes('Firefox' && !ua.includes('Seamonkey'))) {
      return 'Firefox';
    } else if (ua.includes('Chrome') && !ua.includes('Chromium')) {
      return 'Google Chrome';
    } else if (ua.includes('MSIE')) {
      return 'Internet Explorer';
    } else if (
      ua.includes('Safari') &&
      !ua.includes('Chrome') &&
      !ua.includes('Chromium')
    ) {
      return 'Safari';
    } else {
      return 'Other';
    }
  };

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    loginAnonymously: () => dispatch(loginAnonymously()),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracker);
