import { SHOW_GROWL, REMOVE_GROWL, SET_TIMEOUT } from './actionTypes';
import uuidv4 from 'uuid/v4';

function growl({ message, type }) {
  return (dispatch, getState) => {
    const notification = {
      message,
      type,
      id: uuidv4(),
    };

    const { timeout } = getState().notifications;
    // console.log('Timeout: ', timeout);

    dispatch(showGrowl(notification));

    window.setTimeout(() => {
      dispatch(removeGrowl(notification.id));
    }, timeout);
  };
}

function showGrowl(notification) {
  return {
    type: SHOW_GROWL,
    payload: {
      message: notification.message,
      type: notification.type,
      id: notification.id,
    },
  };
}

function removeGrowl(id) {
  return {
    type: REMOVE_GROWL,
    payload: id,
  };
}

function setTimeout(timeout) {
  return {
    type: SET_TIMEOUT,
    payload: timeout,
  };
}

export { growl, removeGrowl, setTimeout };
