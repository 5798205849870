import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const Modal = ({ children, onClose, background, header }) => {
  return (
    <Wrapper>
      <ModalContentWrapper
        background={background}
        className="modal__modal-content"
      >
        <div className="modal__header">
          <span className="modal__header__text">{header}</span>
          <span className="modal__close-button" onClick={onClose}>
            &#10005;
          </span>
        </div>
        <div className="modal__inner-wrapper">{children}</div>
      </ModalContentWrapper>
    </Wrapper>
  );
};

Modal.propTypes = {
  // Modal content.
  children: PropTypes.any,
  // Callback function called when close button is clicked.
  onClose: PropTypes.func,
  // Custom background color.
  background: PropTypes.string,
  // Header text/node to display.
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const Wrapper = Styled.div`
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);

  .modal__header {
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    z-index: 10;
  }

  .modal__header__text {
    height: 100%;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 24px;
    font-size: 24px;
  }
  
  .modal__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-size: 24px;
  }
`;

const ModalContentWrapper = Styled.div`
  position: absolute;
  overflow-y: auto;
  overflow: hidden;
  background: ${({ background }) => (background ? background : '#FFF')};
  border-radius: 4px;
  padding: 24px;
  padding-top: 48px;
  z-index: 1001;
  top: 12px;
  right: 12px;
  left: 12px;
  bottom: 12px;

  .modal__inner-wrapper {
    overflow-y: auto;
    position: absolute;
    height: calc(100% - 50px);
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 24px;
  }

  @media screen and (max-width: 540px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
`;

export default Modal;
