import { css } from 'styled-components';

const theme = {
  primary: {
    // Color scheme.
    colors: {
      teal: {
        base: 'hsl(171, 100%, 41%)',
        lighter: 'hsl(171, 100%, 46%)',
        lightest: 'hsl(171, 100%, 51%)',
        darker: 'hsl(171, 100%, 31%)',
        darkest: 'hsl(171, 100%, 21%)',
        // For softer background colors.
        soft: 'hsl(171, 50%, 85%)',
      },
      darkBlue: {
        base: 'hsl(217, 71%, 53%)',
        lighter: 'hsl(217, 71%, 58%)',
        lightest: 'hsl(217, 71%, 61%)',
        darker: 'hsl(217, 71%, 43%)',
        darkest: 'hsl(217, 71%, 33%)',
        soft: 'hsl(217, 50%, 85%)',
      },
      lightBlue: {
        base: 'hsl(204, 86%, 53%)',
        lighter: 'hsl(204, 86%, 58%)',
        lightest: 'hsl(204, 86%, 63%)',
        darker: 'hsl(204, 86%, 43%)',
        darkest: 'hsl(204, 86%, 33%)',
        soft: 'hsl(204, 50%, 85%)',
      },
      green: {
        base: 'hsl(141, 71%, 48%)',
        lighter: 'hsl(141, 71%, 58%)',
        lightest: 'hsl(141, 71%, 63%)',
        darker: 'hsl(141, 71%, 38%)',
        darkest: 'hsl(141, 71%, 33%)',
        soft: 'hsl(141, 50%, 85%)',
      },
      yellow: {
        base: 'hsl(48, 100%, 67%)',
        lighter: 'hsl(48, 100%, 73%)',
        lightest: 'hsl(48, 100%, 73%)',
        darker: 'hsl(48, 100%, 57%)',
        darkest: 'hsl(48, 100%, 52%)',
        soft: 'hsl(48, 50%, 85%)',
      },
      orange: {
        base: 'hsl(17, 87%, 56%)',
        lighter: 'hsl(17, 87%, 58%)',
        lightest: 'hsl(17, 87%, 60%)',
        darker: 'hsl(17, 87%, 46%)',
        darkest: 'hsl(17, 87%, 44%)',
        soft: 'hsl(17, 50%, 85%)',
      },
      red: {
        base: 'hsl(348, 100%, 61%)',
        lighter: 'hsl(348, 100%, 64%)',
        lightest: 'hsl(348, 100%, 66%)',
        darker: 'hsl(348, 100%, 51%)',
        darkest: 'hsl(348, 100%, 46%)',
        soft: 'hsl(348, 50%, 85%)',
      },
      black: 'hsl(0, 0%, 7%)',
      white: 'hsl(0, 0%, 98%)',
      gray: {
        base: 'hsl(0, 0%, 71%)',
        lighter: 'hsl(0, 0%, 86%)',
        lightest: 'hsl(0, 0%, 91%)',
        darker: 'hsl(0, 0%, 69%)',
        darkest: 'hsl(0, 0%, 49%)',
        soft: 'hsl(0, 10%, 85%)',
      },
      // Gray text with a shade of color.  This is useful for text on matching background color.
      text: {
        teal: {
          base: 'hsl(171, 13%, 80%)',
          lighter: 'hsl(171, 13%, 87%)',
          lightest: 'hsl(171, 13%, 94%)',
          darker: 'hsl(171, 13%, 60%)',
          darkest: 'hsl(171, 13%, 40%)',
        },
        darkBlue: {
          base: 'hsl(217, 13%, 80%)',
          lighter: 'hsl(217, 13%, 87%)',
          lightest: 'hsl(217, 13%, 94%)',
          darker: 'hsl(217, 13%, 60%)',
          darkest: 'hsl(217, 13%, 40%)',
        },
        lightBlue: {
          base: 'hsl(204, 13%, 80%)',
          lighter: 'hsl(204, 13%, 87%)',
          lightest: 'hsl(204, 13%, 94%)',
          darker: 'hsl(204, 13%, 60%)',
          darkest: 'hsl(204, 13%, 40%)',
        },
        green: {
          base: 'hsl(141, 13%, 80%)',
          lighter: 'hsl(141, 13%, 87%)',
          lightest: 'hsl(141, 13%, 94%)',
          darker: 'hsl(141, 13%, 60%)',
          darkest: 'hsl(141, 13%, 40%)',
        },
        yellow: {
          base: 'hsl(48, 13%, 80%)',
          lighter: 'hsl(48, 13%, 87%)',
          lightest: 'hsl(48, 13%, 94%)',
          darker: 'hsl(48, 13%, 60%)',
          darkest: 'hsl(48, 13%, 40%)',
        },
        orange: {
          base: 'hsl(17, 13%, 80%)',
          lighter: 'hsl(17, 13%, 87%)',
          lightest: 'hsl(17, 13%, 94%)',
          darker: 'hsl(17, 13%, 60%)',
          darkest: 'hsl(17, 13%, 40%)',
        },
        red: {
          base: 'hsl(348, 13%, 80%)',
          lighter: 'hsl(348, 13%, 87%)',
          lightest: 'hsl(348, 13%, 94%)',
          darker: 'hsl(348, 13%, 60%)',
          darkest: 'hsl(348, 13%, 40%)',
        },
        black: 'hsl(0, 0%, 7%)',
        white: 'hsl(0, 0%, 98%)',
        gray: {
          base: 'hsl(0, 0%, 71%)',
          lighter: 'hsl(0, 0%, 86%)',
          lightest: 'hsl(0, 0%, 91%)',
          darker: 'hsl(0, 0%, 51%)',
          darkest: 'hsl(0, 0%, 31%)',
        },
      },
    },
    font: {
      fontFamily: 'lato, sans-serif',
      sizes: {
        small: {
          fontSize: '14px',
          lineHeight: '1.5',
        },
        smaller: {
          fontSize: '16px',
          lineHeight: '1.5',
        },
        normal: {
          fontSize: '18px',
          lineHeight: '1.5',
        },
        larger: {
          fontSize: '24px',
          lineHeight: '1.35',
        },
        large: {
          fontSize: '30px',
          lineHeight: '1.15',
        },
      },
    },
  },
};

const textShadowMixin = css`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const eyebrowMixin = css`
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 80%;
`;

export default theme;

export { textShadowMixin, eyebrowMixin };
