import React from 'react';
import { Link } from 'gatsby';

import Styled from 'styled-components';

import config from '../config';

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="footer__row">
        <div className="footer-section footer__services-section">
          <h4 className="red-border">Services</h4>
          <ul>
            <li>
              <Link to="/oriental-rug-cleaning">
                Oriental and Area Rug Cleaning
              </Link>
            </li>
            <li>
              <Link to="/carpet-cleaning">Carpet Cleaning</Link>
            </li>
            <li>
              <Link to="/upholstery-cleaning">Upholstery Cleaning</Link>
            </li>
            <li>
              <Link to="/leather-cleaning">Leather Cleaning</Link>
            </li>
            <li>
              <Link to="/air-duct-cleaning">Air-Duct Cleaning</Link>
            </li>
            <li>
              <Link to="/tile-and-grout-cleaning">Tile and Grout Cleaning</Link>
            </li>
            <li>
              <Link to="/emergency-water-extraction">
                24-Hour Emergency Deep Pad Water Extraction
              </Link>
            </li>
            <li>
              <Link to="/pet-odor-removal">Pet Odor Removal</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section footer__company-section">
          <h4 className="green-border">Company</h4>
          <ul>
            <li>
              <Link to="/aboutus">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <a href="/employment-application">Employment Application</a>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/blog">Our Blog</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section footer__special-pages-section">
          <h4 className="blue-border">Special Pages</h4>
          <ul>
            <li>
              <Link to="/">Home page</Link>
            </li>
            <li>
              <Link to="/rug-cleaning">Rug Cleaning</Link>
            </li>
            <li>
              <Link to="/move-out-checklist">
                Move-Out Inspection Checklist
              </Link>
            </li>
            <li>
              <a href="./sitemap.xml">Sitemap</a>
            </li>
          </ul>
        </div>
      </div>
      <hr style={{ marginTop: '48px', marginBottom: '48px' }} />
      <div className="footer__row">
        <div className="footer-section">
          <address style={{ fontStyle: 'normal' }}>
            <p style={{ lineHeight: '1.5em' }}>
              <span>
                <strong>Sci-Tech Carpet Cleaning</strong>
              </span>
              <br />
              <span>740 Radford St.</span>
              <br />
              <span>Christiansburg, VA 24073</span>
            </p>
          </address>
          <p>
            <span style={{ marginBottom: '6px', display: 'inline-block' }}>
              Primary office phone number
            </span>
            <br />
            <span>{config.phoneNumber}</span>
          </p>
        </div>
        <div className="footer-section">
          <h4>We Proudly Serve</h4>
          <ul>
            <li>Roanoke</li>
            <li>Blacksburg</li>
            <li>Christiansburg</li>
            <li>And all surrounding areas</li>
          </ul>
        </div>
        <div className="footer-section" />
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = Styled.footer`
  background-color: #F3F3F3;
  padding: 2em;
	min-height: 5em;
  font-size: 16px;

  .footer__row {
    display: flex;
    justify-content: space-between;
  }

  .footer-section {
    width: 28%;
  }

  @media (max-width: 700px) {
    .footer__row {
      display: block;
    }

    .footer-section {
      width: auto;
    }
  }
  
  h3 {
    margin-bottom: 1em;
  }

  h4 {
    margin-bottom: 1em;

    &:after {
      content: "";
      display: block;
      margin-top: 10px;
      width: 3em;
      border-bottom: 2px solid black;
    }

    &.red-border:after {
      border-color: #E96656;
    }

    &.green-border:after {
      border-color: #34D293;
    }

    &.blue-border:after {
      border-color: #3AB0E2;
    }

    &.yellow-border:after {
      border-color: #F7D861;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-left: 0;

    li {
      margin-bottom: .5em;

      a {
        color: #333333;
        /* Underline animation */
        position: relative;
        text-decoration: none;

        &:visited, &:hover, &:active {
          color: #333333;
        }
      }
    }
  }

  .footer__services-section ul li a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #E96656;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  .footer__services-section ul li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .footer__company-section ul li a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #34D293;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  .footer__company-section ul li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .footer__special-pages-section ul li a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #3AB0E2;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  .footer__special-pages-section ul li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  hr {
    margin: 1.5em auto;
    width: 85%;
    border-color: rgba(0, 0, 0, 0.1);
  }




`;

export default Footer;
