function scrollToBottom(node, animationDuration = 400) {
  let scrollInterval;

  if (scrollInterval || !node) {
    return;
  }

  let scrolling = true;
  scrollInterval = null;

  const TICK_INTERVAL = 5;
  const TICK_DELTA_EQ =
    (node.scrollHeight - (node.scrollTop + node.offsetHeight)) /
    (animationDuration / TICK_INTERVAL);
  const TICK_DELTA = TICK_DELTA_EQ > 1 ? TICK_DELTA_EQ : 1;

  scrollInterval = window.setInterval(() => {
    if (scrolling) {
      node.scrollTop += TICK_DELTA;
    }

    if (node.scrollTop + node.offsetHeight >= node.scrollHeight - TICK_DELTA) {
      scrolling = false;
      node.scrollTo(0, node.scrollHeight);
      window.clearInterval(scrollInterval);
      scrollInterval = undefined;
    }
  }, TICK_INTERVAL);
}

export default scrollToBottom;
