/**
 * Module to handle client-side permissions authorization.
 *
 * Inspired by the npm acl module.
 *
 * Version: 0.0.1
 *
 * Usage
 * ------
 * Import the module.
 * import Authorization from '/path/to/authorization-client';
 *
 * const authorization = Authorization();
 *
 * To grant permissions to a role for an action on a resource, use:
 * authorization.allow(role, resources, actions)
 * The role must be a string representing a role.  The resources and actions can
 * either be a string each or an array of strings.
 *
 * To verify permissions, use:
 * authorization.isAllowed(role, resources, actions)
 * The formats accepted for the arguments are the same as authorization.allow
 * When passing multiple resources and/or actions, permissions must check out for
 * all of them for authorization to be granted.
 *
 */
function authorization() {
  return (function() {
    /**
     * If not an array, converts el to an array.
     * @param {any} el - Any type or an array.
     */
    const _makeArray = el => {
      if (Array.isArray(el)) {
        return el;
      }
      return [el];
    };

    let permissions = {};

    // Will be returned to allow access to these methods.
    const methods = {
      /**
       * Adds permissions for the specified role(s) on the specified resource(s)
       * with the specified action(s).
       *
       * Roles are arbitrary and are user defined.
       * Resources should represent REST resources such as 'users' for a route of /users
       * Actions are either: 'create', 'read', 'update', or 'delete'.
       *
       * Maintains permissions with an example data structure of:
       * {
       *   role: {
       *     resource1: {
       *       read: true,
       *       delete: true
       *     }
       *   }
       * }
       * @param {string|array} roles - The role(s) to add permissions for.
       * @param {string|array} resources - The resource(s) the permissions are for.
       * @param {string|array} actions - The action(s) that can be performed on the resource(s).
       */
      allow: function(roles, resources, actions) {
        roles = _makeArray(roles);
        resources = _makeArray(resources);
        actions = _makeArray(actions);

        roles.forEach(role => {
          if (permissions[role] === undefined) {
            permissions[role] = {};
          }

          resources.forEach(resource => {
            if (permissions[role][resource] === undefined) {
              permissions[role][resource] = {};
            }

            actions.forEach(action => {
              permissions[role][resource][action] = true;
            });
          });
        });
      },
      /**
       * Checks for permissions with respect to role, resources, and actions.
       *
       * @param {string} role - The role to check.
       * @param {string|array} resources - The resources to check.
       * @param {string|array} actions - The actions to check.
       *
       * @returns {boolean} True if permission granted.  False otherwise.
       */
      isAllowed: function(role, resources, actions) {
        resources = _makeArray(resources);
        actions = _makeArray(actions);

        if (permissions['*'] === undefined && permissions[role] === undefined) {
          return false;
        }

        // TODO:  Support wildcards.

        for (let i = 0; i < resources.length; i++) {
          if (permissions[role][resources[i]] === undefined) {
            return false;
          }
          for (let j = 0; j < actions.length; j++) {
            if (permissions[role][resources[i]][actions[j]] !== true) {
              return false;
            }
          }
        }

        return true;
      },
      getPermissions: function() {
        return permissions;
      },
    };

    return methods;
  })();
}

export default authorization;
