const authorizationRules = [
  {
    roles: ['sales'],
    resources: ['users'],
    actions: ['read'],
  },
  {
    roles: ['sales'],
    resources: ['leads', 'chats', 'visitors'],
    actions: ['create', 'read', 'update', 'delete'],
  },
  {
    roles: ['admin'],
    resources: [
      'users',
      'leads',
      'employment-applications',
      'chats',
      'visitors',
    ],
    actions: ['create', 'read', 'update', 'delete'],
  },
];

export default authorizationRules;
