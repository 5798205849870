import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { growl } from '../redux/actions/notifications/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';
import classnames from 'classnames';

import io from 'socket.io-client';

import theme from '../styles/theme';
import SVG from '../primespot-ui/components/svg';
import Toggle from '../primespot-ui/components/toggle';

import scrollToBottom from '../util/scrollToBottom';
import config from '../config';
import Axios from 'axios';

// Socket emit API constants.
const SEND_MESSAGE = 'conversations.messages.new';

/**
 * Views
 * -----
 * Widget - The FAB widget.
 * Chat - The chat window.
 * CTA - Replace the chat window with a multipe-choice CTA.
 */
class LiveChat extends Component {
  state = {
    socketConnected: true,
    popupMessagesOpen: false,
    chatWindowOpen: false,
    conversation: null,
    showSubHeader: true,
    message: '',
  };

  static propTypes = {
    visitorId: PropTypes.string,
    growl: PropTypes.func,
  };

  static defaultProps = {
    growl: () => {},
  };

  constructor(props) {
    super(props);

    this.messages = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;

    this.conversationsSocket = io(`${config.backendServer}/conversations`);

    this.conversationsSocket.on('connect', () => {
      this._isMounted && this.setState({ socketConnected: true });
    });

    this.conversationsSocket.on('disconnect', () => {
      this._isMounted && this.setState({ socketConnected: false });
    });

    this.props.user && this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.subscribe();
    }

    if (!prevState.chatWindowOpen && this.state.chatWindowOpen) {
      scrollToBottom(this.messages.current);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  subscribe = () => {
    this.loadData();
    this.setListeners();
  };

  loadData = () => {
    // Get contactId stored in localStorage.
    // this.contactId = window.localStorage.getItem('contactId');
    // Get conversationId stored in localStorage.
    this.conversationId = window.localStorage.getItem('conversationId');
    if (this.conversationId) {
      this.conversationsSocket.emit(
        'conversations.is.open',
        { conversation: this.conversationId },
        (err, res) => {
          if (err) {
            console.error(err);
          } else {
            if (!res.open) {
              window.localStorage.removeItem('conversationId');
              this.conversationId = undefined;
            }
          }
        }
      );
    }

    this.conversationsSocket.emit(
      'conversations.subscribe',
      {
        token: this.props.token,
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );

    // Get open conversation (only one allowed) for this user.
    this.conversationsSocket.emit(
      'conversations.get.open',
      {
        token: this.props.token,
      },
      (err, res) => {
        // Load the conversation if it exists.
        if (err) {
          console.error(err);
        } else {
          if (Array.isArray(res)) {
            if (res.length > 0) {
              this._isMounted && this.setState({ conversation: res[0] });
              this.conversationId = res[0]._id;
              window.localStorage.setItem(
                'conversationId',
                this.conversationId
              );
            }
          } else {
            this._isMounted && this.setState({ conversation: res });
            this.conversationId = res._id;
            window.localStorage.setItem('conversationId', this.conversationId);
          }
        }
      }
    );
  };

  setListeners = () => {
    // On receiving a message, create a conversation if it doesn't exist.
    this.conversationsSocket.on('conversations.messages.events.new', data => {
      if (this.conversationId !== data._id) {
        window.localStorage.setItem('conversationId', data._id);
        this.conversationId = data._id;
      }

      this._isMounted &&
        this.setState({ conversation: data }, () => {
          scrollToBottom(this.messages.current);
        });
      if (!this.state.chatWindowOpen) {
        // Display popup if chat widget is closed.
        this._isMounted &&
          !this.state.chatWindowOpen &&
          this.setState({ popupMessagesOpen: true });
      }
    });

    this.conversationsSocket.on('conversations.events.close', conversation => {
      window.localStorage.removeItem('conversationId');
      this.props.growl({
        type: 'info',
        message:
          'This conversation has now been marked as resolved and closed.  Please get in touch if we can help you with anything else!',
      });
    });

    // this.conversationsSocket.on(
    //   'conversations.contact.events.receivedId',
    //   data => {
    //     console.log('Received new contactId,', data.contactId);
    //     // Received a contactId to be assigned to this client.
    //     if (data && data.contactId) {
    //       this.contactId = data.contactId;
    //       window.localStorage.setItem('contactId', data.contactId);
    //     }
    //   }
    // );
  };

  updateChatContact = ({ name, email, phoneNumber }) => {
    Axios.patch(
      `${config.backendServer}/users/${this.props.user._id}`,
      { name, email, phoneNumber },
      {
        Token: this.props.token,
      }
    )
      .then(res => {
        growl({
          type: 'success',
          message: 'Your information has been successfully updated.',
        });
      })
      .catch(err => {
        console.error('An error occurred updating user information.');
        console.error(err);
        this.props.growl({
          type: 'danger',
          message:
            'Something went wrong updating your information.  Please try again.',
        });
      });
    // this.conversationsSocket.emit(
    //   'conversations.contact.patch',
    //   {
    //     contactId: this.contactId,
    //     name,
    //     email,
    //     phoneNumber,
    //   },
    //   (err, res) => {
    //     if (err) {
    //       console.error(err);
    //       this.props.growl({
    //         type: 'danger',
    //         message:
    //           'An error occurred saving your information.  Please try again.',
    //       });
    //     } else {
    //       this._isMounted && this.setState({ showSubHeader: false });
    //     }
    //   }
    // );
  };

  sendMessage = () => {
    const { message } = this.state;
    if (!this.props.user) {
      console.log('No user found. Unable to send message without user.');
      return;
    }

    this.conversationsSocket.emit(
      SEND_MESSAGE,
      {
        message,
        conversation: this.conversationId || undefined,
        token: this.props.token,
      },
      (err, res) => {
        if (err) {
          console.error('An error occurred sending the message.');
          console.error(err);
        } else {
          // Ensure that conversationId is saved.
          if (!this.conversationId) {
            this.conversationId = res._id;
            window.localStorage.setItem('conversationId', res._id);
          }

          const messageBox = document.querySelector(
            '.live-chat__chat-window__controls input'
          );
          messageBox.value = '';
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        {this.state.socketConnected ? (
          <ThemeProvider theme={theme}>
            <Wrapper>
              <ChatWindowWrapper visible={this.state.chatWindowOpen}>
                <div className="live-chat__chat-window__header">
                  <div className="live-chat__chat-window__header__left-wrapper">
                    <SVG
                      image="chat-bubble-dots"
                      className="live-chat__chat-window__header__icon"
                    />
                    Live Chat
                  </div>
                  <div
                    className="live-chat__chat-window__header__right-wrapper"
                    onClick={() => {
                      this.setState(prevState => ({
                        chatWindowOpen: !prevState.chatWindowOpen,
                      }));
                    }}
                  >
                    &times;
                  </div>
                </div>
                {this.state.showSubHeader && (
                  <SubHeader
                    updateChatContact={this.updateChatContact}
                    changeSubHeaderVisibility={val =>
                      this.setState({ showSubHeader: val })
                    }
                  />
                )}
                <div className="live-chat__chat-window__body">
                  <div
                    className="live-chat__chat-window__body__messages"
                    ref={this.messages}
                  >
                    {this.state.conversation &&
                      this.state.conversation.messages &&
                      this.state.conversation.messages.map(message => {
                        const messageClasses = classnames(
                          'live-chat__chat-window__body__message',
                          {
                            'from-me': message.user._id === this.props.user._id,
                          }
                        );
                        return (
                          <div className={messageClasses} key={message._id}>
                            {message.message}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="live-chat__chat-window__controls">
                  <label>
                    <input
                      type="text"
                      placeholder="Type a message..."
                      value={this.state.message}
                      onChange={ev => {
                        ev.preventDefault();

                        const val = ev.target.value;
                        this.setState({ message: val });
                      }}
                      onKeyDown={ev => {
                        if (ev.keyCode === 13) {
                          const input = document.querySelector(
                            '.live-chat__chat-window__controls input'
                          );
                          this.sendMessage();
                          input.value = '';
                        }
                      }}
                    />
                  </label>
                  <SVG
                    image="send"
                    className="live-chat__chat-window__controls__send-button"
                    style={{
                      transition: 'all .3s ease',
                      opacity: this.state.message === '' ? 0.2 : 1,
                      pointerEvents:
                        this.state.message === '' ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      const input = document.querySelector(
                        '.live-chat__chat-window__controls input'
                      );
                      this.sendMessage();
                      input.value = '';
                    }}
                  />
                </div>
              </ChatWindowWrapper>
              <WidgetWrapper className="live-chat__widget">
                {this.state.popupMessagesOpen &&
                  this.state.conversation &&
                  this.state.conversation.messages && (
                    <div className="live-chat__widget__messages">
                      <span
                        className="live-chat__widget__message__close-button"
                        onClick={() => {
                          this.setState({ popupMessagesOpen: false });
                        }}
                      >
                        &times;
                      </span>
                      {this.state.conversation.messages
                        .filter(m => m.user._id !== this.props.user._id)
                        .map((message, index) =>
                          index < 5 ? (
                            <div
                              className="live-chat__widget__message"
                              key={message._id}
                            >
                              <Fragment>{message.message}</Fragment>
                            </div>
                          ) : (
                            <div key={message._id} />
                          )
                        )}
                    </div>
                  )}
                <div
                  className="live-chat__widget__fab"
                  onClick={() => {
                    this.setState(prevState => ({
                      chatWindowOpen: !prevState.chatWindowOpen,
                    }));
                  }}
                >
                  <div
                    className={`live-chat__widget__fab__close-button${
                      this.state.chatWindowOpen === true ? ' show' : ''
                    }`}
                  >
                    &times;
                  </div>
                  <SVG
                    className={`live-chat__widget__fab__main-icon${
                      this.state.chatWindowOpen === false ? ' show' : ''
                    }`}
                    image="chat-bubble-dots"
                  />
                </div>
              </WidgetWrapper>
            </Wrapper>
          </ThemeProvider>
        ) : (
          <div />
        )}
      </Fragment>
    );
  }
}

const SubHeader = props => {
  return (
    <div className="live-chat__chat-window__sub-header">
      <Toggle>
        {({ toggle, toggled }) => {
          const expandableClasses = classnames('expandable', {
            expanded: toggled,
          });
          return (
            <Fragment>
              <p className={toggled ? 'expanded' : ''}>
                In case we get disconnected, please tell us your contact
                information so we can reach out.
              </p>
              <button onClick={toggle}>{toggled ? 'Hide' : 'Ok'}</button>
              <button
                onClick={() => {
                  props.changeSubHeaderVisibility(false);
                }}
              >
                No thank you
              </button>
              <div className={expandableClasses}>
                <label>
                  <div>Name</div>
                  <input
                    placeholder="Jane Smith"
                    id="live-chat__chat-contact__name"
                  />
                </label>
                <label>
                  <div>Email</div>
                  <input
                    placeholder="jane.smith@email.com"
                    id="live-chat__chat-contact__email"
                  />
                </label>
                <label>
                  <div>Phone number</div>
                  <input
                    placeholder="540-555-1212"
                    id="live-chat__chat-contact__phone-number"
                  />
                </label>
                <button
                  onClick={() => {
                    const nameInput = document.querySelector(
                      '#live-chat__chat-contact__name'
                    );
                    const emailInput = document.querySelector(
                      '#live-chat__chat-contact__email'
                    );
                    const phoneNumberInput = document.querySelector(
                      '#live-chat__chat-contact__phone-number'
                    );

                    props.updateChatContact({
                      name: nameInput.value,
                      email: emailInput.value,
                      phoneNumber: phoneNumberInput.value,
                    });
                  }}
                >
                  Save
                </button>
              </div>
            </Fragment>
          );
        }}
      </Toggle>
    </div>
  );
};

const Wrapper = Styled.div`
  z-index: 10000;
`;

const ChatWindowWrapper = Styled.div`
  width: 280px;
  height: 400px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  transition: all .3s ease;
  position: fixed;
  right: 24px;
  bottom: 102px;
  z-index: 10001;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
  overflow: hidden;
  z-index: 10001;

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  .live-chat__chat-window__header {
    height: 50px;
    width: 100%;
    background-color: ${({ theme }) => theme.primary.colors.orange.base};
    color: white;
    display: flex;
    flex-shrink: 0;
    flex-basis: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
  }

  .live-chat__chat-window__header__left-wrapper {
    display: flex;
    align-items: center;
  }

  .live-chat__chat-window__header__right-wrapper {
    font-size: 30px;
    cursor: pointer;
  }

  .live-chat__chat-window__header__icon {
    fill: white;
    height: 24px;
    width: 24px;
    margin-right: 6px;
  }

  .live-chat__chat-window__sub-header {
    background-color: rgb(245, 245, 245);
    padding: 0 12px;
    font-size: 16px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);

    p {
      transition: max-height .3s ease-in;
      overflow: hidden;
    }

    button {
      padding: 0;
      display: inline-block;
      margin: 0 24px 12px 0;
      cursor: pointer;
      transition: all .3s ease;
      color: ${({ theme }) => theme.primary.colors.lightBlue.base};
      background: transparent;
      border: none;

      &:hover {
        color: ${({ theme }) => theme.primary.colors.darkBlue.base};
      }
    }

    label {
      margin-bottom: 12px;
      display: inline-block;
    }

    label div {
      margin-bottom: 6px;
    }

    input {
      box-sizing: border-box;
      padding: 9px 12px;
      background: rgb(235, 235, 235);
      border: none;
      border-radius: 4px;
      color: #333;
    }
  }

  .live-chat__chat-window__sub-header p.expanded {
    max-height: 0;
    margin: 0;
    margin-bottom: 12px;
  }

  .expandable {
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease-in;
    box-sizing: border-box;
  }

  .expandable.expanded {
    max-height: 400px;
    overflow: auto;
    padding: 0 6px;
  }

  .live-chat__chat-window__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .live-chat__chat-window__body__messages {
    display: flex;
    /* flex-grow: 1; */
    flex-direction: column;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .live-chat__chat-window__body__message {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 18px;
    width: 60%;
    padding: 12px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.primary.colors.gray.lightest};
    border-radius: 4px;
    justify-content: flex-start;
    align-self: flex-start;
    position: relative;
  }

  .live-chat__chat-window__body__message.from-me {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.soft};
    justify-content: flex-end;
    align-self: flex-end;
  }

  .live-chat__chat-window__controls {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    flex-basis: 48px;
    box-sizing: border-box;
    padding: 0 6px;
    background: white;
    z-index: 10;
    background-color: rgb(245, 245, 245);

    label {
      width: calc(100% - 42px);
    }

    input {
      width: 100%;
      padding: 6px;
      border: none;
      background-color: rgb(245, 245, 245);
    }
  }

  .live-chat__chat-window__controls__send-button {
    fill: ${({ theme }) => theme.primary.colors.orange.base};
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const WidgetWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10000;

  .live-chat__widget__fab {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary.colors.orange.base};
    width: 66px;
    height: 66px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    cursor: pointer;
    transition: all .3s ease;
    color: white;
    font-size: 24px;

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .7);
    }
  }

  .live-chat__widget__messages {
    position: relative;
    padding-top: 36px;
  }

  .live-chat__widget__message {
    background: white;
    color: #333;
    padding: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    margin: 0 0 18px 0;
    border-radius: 4px;
    max-width: 280px;
    cursor: default;
    position: relative;
  }

  .live-chat__widget__message__close-button {
    color: ${({ theme }) => theme.primary.colors.gray.base};
    position: absolute;
    top: 6px;
    right: 3px;
    cursor: pointer;
    font-size: 30px;
    transition: all .3s ease;
    line-height: 15px;
  }

  .live-chat__widget__fab__main-icon {
    fill: white;
    opacity: 0;
    width: 0;
    height: 0;
    transition: all .3s ease;

    &.show {
      opacity: 1;
      width: 27px; 
      height: 27px;
    }
  }

  .live-chat__widget__fab__close-button {
    opacity: 0;
    transition: all .3s ease;
    font-size: 0;
    padding-bottom: 6px;
    user-select: none;

    &.show {
      opacity: 1;
      font-size: 48px;
    }
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ type, message }) => {
      dispatch(growl({ type, message }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveChat);
