import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Styled from 'styled-components';

class SimpleDropdown extends Component {
  state = {
    expanded: false,
  };

  static propTypes = {
    activator: PropTypes.node,
    children: PropTypes.node,
    mode: PropTypes.string,
    passedContentClass: PropTypes.string,
  };

  static defaultProps = {
    className: 'simple-dropdown',
    mode: 'hover',
    passedContentClass: 'simple-dropdown__content__inner__passed-content',
  };

  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
  }

  onActivatorClick = () => {
    if (this.props.mode !== 'click') {
      return;
    }

    this.expand();
  };

  onActivatorHoverStart = () => {
    if (this.props.mode !== 'hover') {
      return;
    }

    this.expand();
  };

  onActivatorHoverEnd = () => {
    if (this.props.mode !== 'hover') {
      return;
    }

    this.contract();
  };

  expand = () => {
    this.setState({ expanded: true });
  };

  contract = () => {
    this.setState({ expanded: false });
  };

  render() {
    const { activator, children } = this.props;

    const dropdownClasses = classnames(this.props.className, {
      expanded: this.state.expanded,
    });

    const contentClasses = classnames({
      'simple-dropdown__content': true,
      expanded: this.state.expanded,
    });

    return (
      <Wrapper
        className={dropdownClasses}
        expanded={this.state.expanded}
        onMouseEnter={this.onActivatorHoverStart}
        onMouseLeave={this.onActivatorHoverEnd}
      >
        <div
          className="simple-dropdown__activator"
          onClick={this.onActivatorClick}
        >
          {activator}
          <div
            ref={this.contentRef}
            className={contentClasses}
            onClick={this.onActivatorClick}
          >
            <div className="simple-dropdown__content__inner">
              <div className="simple-dropdown__content__arrow" />
              {/* Blank div to fix collapsing margin bug within children. */}
              <div />
              <div className={this.props.passedContentClass}>{children}</div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  position: relative;

  * {
    box-sizing: border-box;
  }

  .simple-dropdown__activator {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: default;
  }

  .simple-dropdown__content {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(100% + 24px);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity .3s ease, top .3s ease;
    z-index: 10000;
    padding-top: 12px;
  }

  .simple-dropdown__content.expanded {
    opacity: 1;
    top: 100%;
    pointer-events: auto;
  }

  .simple-dropdown__content__inner {
    box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    border-radius: 4px;
    background: white;
    position: relative;
  }

  .simple-dropdown__content__arrow {
    position: absolute;
    top: 1px;
    left: calc(50% + 9px);
    border: 9px solid black;
    border-color: transparent transparent #FFF #FFF;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.15);
  }
`;

export default SimpleDropdown;
