import AuthorizationClient from '../../../util/authorization-client';
import authorizationRules from '../../../util/permissions';

const authorization = AuthorizationClient();

if (authorizationRules) {
  authorizationRules.forEach(rule => {
    authorization.allow(rule.roles, rule.resources, rule.actions);
  });
}

function allow(roles, resources, actions) {
  return dispatch => {
    authorization.allow(roles, resources, actions);
  };
}

function isAllowed(role, resources, actions) {
  return dispatch => {
    return authorization.isAllowed(role, resources, actions);
  };
}

export { allow, isAllowed };
