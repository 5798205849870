import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  growl,
  removeGrowl,
  setTimeout,
} from '../redux/actions/notifications/actionCreators';

import Styled from 'styled-components';

class NotificationProvider extends Component {
  // state = {
  //   count: 0,
  //   growls: [],
  // };

  static propTypes = {
    // Time in milliseconds before the notification disappears.
    timeout: PropTypes.number,
    // Function as a child.
  };

  static defaultProps = {
    timeout: 5000,
  };

  // growl = ({ message, type }) => {
  //   const notification = {
  //     message,
  //     type,
  //     id: this.state.count,
  //   };

  //   this.setState(
  //     prevState => ({ count: prevState.count + 1 }),
  //     () => {
  //       this.setState(prevState => ({
  //         growls: [...prevState.growls, notification],
  //       }));
  //       window.setTimeout(() => {
  //         this.setState(prevState => ({
  //           growls: prevState.growls.filter(g => g.id !== notification.id),
  //         }));
  //       }, this.props.timeout || 5000);
  //     }
  //   );
  // };

  handleGrowlClick = id => {
    // this.setState(prevState => ({
    //   growls: prevState.growls.filter(g => g.id !== id),
    // }));
    this.props.removeGrowl(id);
  };

  render() {
    const growls = this.props.growls.map(growl => (
      <CSSTransition
        key={growl.id}
        timeout={500}
        classNames="growl-notification"
      >
        <Growl
          type={growl.type}
          onClick={() => {
            this.handleGrowlClick(growl.id);
          }}
        >
          {growl.message}
        </Growl>
      </CSSTransition>
    ));
    return (
      <Fragment>
        <Wrapper className="notification-provider__growl-list">
          <TransitionGroup component={null}>{growls}</TransitionGroup>
        </Wrapper>
      </Fragment>
    );
  }
}

const Wrapper = Styled.div`
  position: fixed;
  width: 240px;
  top: 70px;
  right: 24px;
  background: transparent;
  z-index: 10001;

  & > .growl-notification-enter {
    opacity: .01;
    margin-left: 500px;
  }

  & > .growl-notification-enter-active {
    opacity: 1;
    margin-left: 0;
    transition: all .5s ease;
  }

  & > .growl-notification-exit {
    opacity: 1;
    margin-left: 0;
  }

  & > .growl-notification-exit-active {
    opacity: .01;
    margin-left: 500px;
    transition: all .5s ease;
  }
`;

const Growl = Styled.div`
  width: 100%;
  background-color: ${({ type }) => {
    if (type === 'danger') {
      return '#FF4136';
    } else if (type === 'warning') {
      return '#FFDC57';
    } else if (type === 'success') {
      return '#23D160';
    } else if (type === 'info') {
      return '#209CEE';
    } else {
      return '#209CEE';
    }
  }};
  color: white;
  padding: 18px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
  transition: all .3s ease;
  cursor: default;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
  z-index: 10000;

  &:hover {
    opacity: .8;
  }
`;

const mapStateToProps = state => {
  return {
    growls: state.notifications.growls,
    timeout: state.notifications.timeout,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    removeGrowl: id => dispatch(removeGrowl(id)),
    setTimeout: timeout => dispatch(setTimeout(timeout)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProvider);
