import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import Styled, { ThemeProvider } from 'styled-components';
import classnames from 'classnames';
import { MdLocalPhone } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdDescription } from 'react-icons/md';

import Dropdown from '../primespot-ui/components/simpleDropdown';
import SVG from '../primespot-ui/components/svg';
import Modal from '../primespot-ui/components/modal';

import Logo from '../images/alpha-logo.png';
import LogoSymbol from '../images/atomic-symbol.png';

import theme from '../styles/theme';
import config from '../config';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll(ev) {
    if (window.scrollY >= 5) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  function toggle() {
    setIsMenuOpen(prev => !prev);
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const classes = classnames({
    scrolled: isScrolled,
  });

  return (
    <ThemeProvider theme={theme}>
      <ContactWrapper className="contact-header">
        <ul className="whitespace-no-wrap">
          <li>
            <MdLocalPhone
              size={16}
              color="#555"
              style={{ marginRight: '6px' }}
            />
            <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
          </li>
          <li className="sm:hidden">
            <MdEmail size={16} color="#555" style={{ marginRight: '6px' }} />
            <a href="/contact">Send us a message</a>
          </li>
          <li>
            <MdDescription
              size={16}
              color="#555"
              style={{ marginRight: '6px' }}
            />
            <a href="/price-quote">Get a price quote</a>
          </li>
        </ul>
      </ContactWrapper>
      <MainWrapper className={classes}>
        <div className="header__desktop">
          <div className="header__desktop__left-wrapper">
            <Link to="/" className="logo__wrapper">
              <img
                src={LogoSymbol}
                alt="Symbol"
                className="logo__symbol"
                width="36"
                height="36"
              />
              <img src={Logo} alt="Logo" className="logo" />
            </Link>
            <Dropdown
              className="header__desktop__item services-dropdown"
              activator={
                <div className="services-dropdown__activator-text">
                  Services
                </div>
              }
            >
              <div className="services-dropdown__content">
                <div className="services-dropdown__content__primary">
                  <ul>
                    <li>
                      <Link to="/oriental-rug-cleaning">
                        <div className="services-dropdown__title">
                          <div className="services-dropdown__content__icon rug-icon">
                            <SVG image="link" />
                          </div>
                          <h3 className="rug-title">
                            Oriental and Area Rug Cleaning
                          </h3>
                        </div>
                        <p>
                          Completely restore your rugs including full pet odor
                          removal.
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/carpet-cleaning">
                        <div className="services-dropdown__title">
                          <div className="services-dropdown__content__icon cc-icon">
                            <SVG image="link" />
                          </div>
                          <h3 className="cc-title">Carpet Cleaning</h3>
                        </div>
                        <p>Premium service at an affordable price.</p>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="services-dropdown__content__secondary">
                  <ul>
                    <li>
                      <Link to="/upholstery-cleaning">
                        <div className="services-dropdown__content__icon">
                          <SVG image="link" />
                        </div>
                        <h3>Upholstery Cleaning</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/leather-cleaning">
                        <div className="services-dropdown__content__icon">
                          <SVG image="link" />
                        </div>
                        <h3>Leather Cleaning</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tile-and-grout-cleaning">
                        <div className="services-dropdown__content__icon">
                          <SVG image="link" />
                        </div>
                        <h3>Tile and Grout Cleaning</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/air-duct-cleaning">
                        <div className="services-dropdown__content__icon">
                          <SVG image="link" />
                        </div>
                        <h3>Air-Duct Cleaning</h3>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Dropdown>
            <Dropdown
              className="header__desktop__item services-dropdown"
              activator={
                <div className="services-dropdown__activator-text">Company</div>
              }
            >
              <div className="services-dropdown__content">
                <div className="services-dropdown__content__primary">
                  <ul>
                    <li>
                      <Link to="/">
                        <h3>Home</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/aboutus">
                        <h3>About Us</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        <h3>Our Blog</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <h3>Contact Us</h3>
                      </Link>
                    </li>
                    <li>
                      <Link to="/employment-application">
                        <h3>Apply For Employment</h3>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="header__desktop__right-wrapper">
            <a
              className={`header__call-button${
                isScrolled ? ' is-scrolled' : ''
              }`}
              href={config.phoneNumberHref}
            >
              {config.phoneNumber}
            </a>
          </div>
        </div>

        <div className="header__mobile">
          <Link className="mobile-logo-link" to="/">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
          <span className="nav-menu-toggle" onClick={toggle}>
            Menu <SVG className="hamburger-icon" image="menu" />
          </span>
        </div>
        {isMenuOpen && (
          <Modal onClose={toggle} header="Menu">
            <div className="header__modal__container">
              <ul
                onClick={() => {
                  document.body.style.overflow = 'auto';
                }}
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/oriental-rug-cleaning">
                    Oriental and Area Rug Cleaning
                  </Link>
                </li>
                <li>
                  <Link to="/carpet-cleaning">Carpet Cleaning</Link>
                </li>
                <li>
                  <Link to="/upholstery-cleaning">Upholstery Cleaning</Link>
                </li>
                <li>
                  <Link to="/leather-cleaning">Leather Cleaning</Link>
                </li>
                <li>
                  <Link to="/air-duct-cleaning">Air Duct Cleaning</Link>
                </li>
                <li>
                  <Link to="/tile-and-grout-cleaning">
                    Tile and Grout Cleaning
                  </Link>
                </li>
                <li>
                  <Link to="/emergency-water-extraction">
                    24-Hour Emergency Water Extraction
                  </Link>
                </li>
                <li>
                  <Link to="/pet-odor-removal">Pet Odor Removal</Link>
                </li>
                <li>
                  <Link to="/blog">Our blog</Link>
                </li>
                <li>
                  <Link to="/employment-application">Apply For Employment</Link>
                </li>
              </ul>
            </div>
          </Modal>
        )}
      </MainWrapper>
    </ThemeProvider>
  );
}

const ContactWrapper = Styled.div`
  height: 36px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background: rgb(245, 245, 245);
  padding-left: 24px;
  padding-right: 24px;
  z-index: 1000;

  a {
    color: #555;
    text-decoration: none;
    transition: all .3s ease;
  }

  a:hover {
    color: #222;
    text-decoration: underline;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #555;
    font-size: 16px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 18px;
  }
`;

const MainWrapper = Styled.div`
  color: #555;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 36px;
  left: 0;
  box-sizing: border-box;
  background-color: #F8F8F8;
  background-color: #FFF;
  padding-left: 24px;
  transition: all .4s ease;
  z-index: 1000;

  .logo__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo__symbol {
    animation: spin 12s linear infinite;
    margin-right: 6px;
  }

  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

  &.scrolled {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  }

  .header__desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .header__desktop__left-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }

  .header__desktop__right-wrapper {
    height: 100%;
  }

  .header__desktop ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .header__desktop ul li {
    
  }

  .header__desktop__item {
    height: 100%;
    box-sizing: border-box;
    padding: 0 24px;
  }

  .services-dropdown__activator-text {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services-dropdown__content {
    min-width: 320px;
    box-sizing: border-box;
    background: white;
  }

  .services-dropdown__content__primary {
    padding: 24px;
  }

  .services-dropdown__content__primary li {
    margin-bottom: 12px;
  }

  .services-dropdown__content__primary a {
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    transition: all .3s ease;
  }

  .services-dropdown__content__primary a:hover, .services-dropdown__content__secondary a:hover {
    .services-dropdown__content__icon {
      background: ${({ theme }) => theme.primary.colors.lightBlue.lighter};
    }

    .services-dropdown__content__icon.rug-icon {
      background: ${({ theme }) => theme.primary.colors.red.darkest};
    }

    .services-dropdown__content__icon.cc-icon {
      background: ${({ theme }) => theme.primary.colors.orange.darkest};
    }

    h3 {
      color: ${({ theme }) => theme.primary.colors.lightBlue.lighter};
    }

    h3.rug-title {
      color: ${({ theme }) => theme.primary.colors.red.darkest} !important;
    }

    h3.cc-title {
      color: ${({ theme }) => theme.primary.colors.orange.darkest} !important;
    }

    p {
      color: #999;
    }
  }

  .services-dropdown__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .services-dropdown .services-dropdown__content__icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background: ${({ theme }) => theme.primary.colors.lightBlue.darker};
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 12px;
    transition: all .3s ease;
  }

  .services-dropdown .services-dropdown__content__icon.rug-icon {
    background: ${({ theme }) => theme.primary.colors.red.base};
  }

  .services-dropdown .services-dropdown__content__icon.cc-icon {
    background: ${({ theme }) => theme.primary.colors.orange.base};
  }

  h3.rug-title {
    color: ${({ theme }) => theme.primary.colors.red.base} !important;
  }

  h3.cc-title {
    color: ${({ theme }) => theme.primary.colors.orange.base} !important;
  }

  .services-dropdown .svg__icon {
    width: 15px;
    height: 15px;
    fill: white;
    transition: all .3s ease;
  }

  .services-dropdown h3 {
    margin: 0;
    margin-bottom: 6px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${({ theme }) => theme.primary.colors.lightBlue.darker};
    transition: all .3s ease;
  }

  .services-dropdown p {
    margin: 0;
    margin-bottom: 18px;
    font-size: 16px;
    color: #777;
    transition: all .3s ease;
  }

  .services-dropdown li:last-child, .services-dropdown li:last-child p {
    margin-bottom: 0;
  }

  .services-dropdown__content__secondary {
    background-color: #FAFAFA;
    padding: 24px;
    border-radius: 0 0 4px 4px;
  }

  .services-dropdown__content__secondary li {
    margin-bottom: 18px;
  }

  .services-dropdown__content__secondary a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .header__call-button {
    height: 100%;
    color: #555;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    text-decoration: none;
    /* padding: 6px 18px; */
    padding-right: 18px;
  }

  .header__call-button.is-scrolled {
    background-color: ${({ theme }) => theme.primary.colors.orange.base};
    color: #FFF;
    padding: 6px 18px;

    &:hover {
      background-color: ${({ theme }) => theme.primary.colors.orange.lightest};
    }
  }

  .header__mobile {
    display: none;
  }

  .header__modal__container {
    ul {
      list-style-type: none;
    }

    li {
      margin-bottom: 18px;
      position: relative;
    }

    a {
      color: #555;
      text-decoration: none;

      &:hover:after {
        content: '';
        display: block;
        height: 6px;
        width: 100px;
        background-color: #FF3860;
        position: absolute;
        margin-top: 3px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0;

    .header__desktop {
      display: none;
    }

    .header__mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      padding-left: 24px;
      padding-right: 36px;
    }

    .hamburger-icon {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }

    .nav-menu-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
`;

export default Header;
