import { css } from 'styled-components';

const baseMixin = css`
  p,
  li,
  a,
  span {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 130%;
  }

  a {
    text-decoration: none;
    color: #337ab7;

    &:hover {
      text-decoration: underline;
      color: #23527c;
    }
  }
`;

const ctaButtonMixin = css`
  .cta-button {
    background-color: #3676d9;
    padding: 6px 12px;
    border-radius: 3px;
    font-size: 18px;
    color: white;
    float: right;
    margin-top: 12px;
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;

    &:hover {
      background-color: #2f69bf;
      color: white;
      text-decoration: none;
    }
  }
`;

const fullWidthMixin = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export { baseMixin, ctaButtonMixin, fullWidthMixin };
