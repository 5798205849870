/**
 * Module that uses the window.matchMedia module to use media queries
 * in JavaScript.
 *
 * @param {string} size - Size parameter in a format such as '720px'.
 *
 * @returns {object} Object containing methods using the matcher.
 */
function matchMediaCheck(size) {
  const matcher = window.matchMedia(`(max-width: ${size})`);

  return {
    /**
     * Checks that the window size is no larger than the specified size.
     *
     * @returns {boolean} True if the window is no larger than the size specified.
     */
    matches: function() {
      return matcher.matches;
    },
    /**
     * Adds a window.resize listener.
     *
     * @param {function} cb - Callback function to execute when the window resizes.
     */
    registerResizeListener: function(cb) {
      matcher.addListener(cb);
    },
    /**
     * Removes window.resize listener.
     */
    removeResizeListener: function(cb) {
      matcher.removeListener(cb);
    },
    /**
     * Gets the raw matcher object.
     *
     * @returns {object} The raw matcher object.
     */
    getMatcher: function() {
      return matcher;
    },
  };
}

export default matchMediaCheck;
