import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Toggle extends Component {
  state = {
    toggled: this.props.toggled,
  };

  static propTypes = {
    // Sets the starting state.
    toggled: PropTypes.bool,
  };

  static defaultProps = {
    toggled: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle = () => {
    this._isMounted &&
      this.setState(prevState => ({ toggled: !prevState.toggled }));
  };

  setToggled = val => {
    this._isMounted && this.setState({ toggled: val });
  };

  render() {
    const passedProps = {
      toggle: this.toggle,
      toggled: this.state.toggled,
      setToggled: this.setToggled,
    };
    return <Fragment>{this.props.children(passedProps)}</Fragment>;
  }
}

export default Toggle;
