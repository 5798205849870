import { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

import config from '../config';

export default function usePresence() {
  const [anyOnline, setAnyOnline] = useState(false);
  const presenceSocket = useRef(null);

  useEffect(() => {
    presenceSocket.current = io(`${config.backendServer}/presence`);
    presenceSocket.current.emit('presence.any.online', (err, res) => {
      if (!err) {
        setAnyOnline(res);
      }
    });

    presenceSocket.current.emit('presence.any.online.watch', err => {
      if (err) {
        console.error(err);
      }
    });

    presenceSocket.current.on('presence.any.online.change', res => {
      if (res === true) {
        setAnyOnline(true);
      } else {
        window.setTimeout(() => {
          if (res === false) {
            presenceSocket.current.emit(
              'presence.any.online',
              (err, innerRes) => {
                if (innerRes === false) {
                  setAnyOnline(false);
                }
              }
            );
          }
        }, 5000);
      }
    });
  }, []);

  return [anyOnline, setAnyOnline, presenceSocket.current];
}
