import React from 'react';

import Styled from 'styled-components';

import config from '../config';

function isIE() {
  if (typeof window !== 'undefined') {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  } else {
    return false;
  }
}

const IE11Notification = ({ onIEDetection }) => {
  if (!isIE()) {
    onIEDetection && onIEDetection();
    return <div />;
  }

  return (
    <Wrapper>
      <p>
        Please note: You are using a heavily outdated web browser. Internet
        Explorer 11 is not supported by this website. Futhermore, you should
        upgrade your browser to enhance your web-viewing experience and improve
        security. Please visit:{' '}
        <a href="https://www.google.com/chrome/">this website</a> to upgrade
        your web browser. Also, you may give us a call at{' '}
        <a href={config.phoneNumberHref}>{config.phoneNumber}</a> for help.
      </p>
    </Wrapper>
  );
};

const Wrapper = Styled.div`
  background-color: #209CEE;
  color: white;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 10%;
  box-sizing: border-box;
  z-index: 10000;
  line-height: 140%;

  a {
    color: #FFDC57;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default IE11Notification;
