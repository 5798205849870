import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component wrapper for SVGs taken from the zondicons image library.
 *
 * @param {Object} props Props object containing at least an image field that dictates which svg image to use.
 */
const SVG = ({ image, ...props }) => {
  switch (image) {
    case 'add-outline':
      return (
        <svg
          className="svg__icon svg__add-outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
        </svg>
      );
    case 'add-solid':
      return (
        <svg
          className="svg__icon svg__add-solid"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z" />
        </svg>
      );
    case 'arrow-thin-up':
      return (
        <svg
          className="svg__icon svg__arrow-thin-up"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M9 3.828L2.929 9.899 1.515 8.485 10 0l.707.707 7.778 7.778-1.414 1.414L11 3.828V20H9V3.828z" />
        </svg>
      );
    case 'arrow-thin-down':
      return (
        <svg
          className="svg__icon svg__arrow-thin-down"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M9 16.172l-6.071-6.071-1.414 1.414L10 20l.707-.707 7.778-7.778-1.414-1.414L11 16.172V0H9z" />
        </svg>
      );
    case 'arrow-thin-left':
      return (
        <svg
          className="svg__icon svg__arrow-thin-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z" />
        </svg>
      );
    case 'arrow-thin-right':
      return (
        <svg
          className="svg__icon svg__arrow-thin-right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z" />
        </svg>
      );
    case 'badge':
      return (
        <svg
          className="svg__icon svg__badge"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 12a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-3a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm4 2.75V20l-4-4-4 4v-8.25a6.97 6.97 0 0 0 8 0z" />
        </svg>
      );
    case 'chart':
      return (
        <svg
          className="svg__icon svg__chart"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
        </svg>
      );
    case 'chart-bar':
      return (
        <svg
          className="svg__icon svg__chart-bar"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z" />
        </svg>
      );
    case 'chart-pie':
      return (
        <svg
          className="svg__icon svg__chart-pie"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M19.95 11A10 10 0 1 1 9 .05V11h10.95zm-.08-2.6H11.6V.13a10 10 0 0 1 8.27 8.27z" />
        </svg>
      );
    case 'chat-bubble-dots':
      return (
        <svg
          className="svg__icon svg__chat-bubble-dots"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z" />
        </svg>
      );
    case 'checkmark':
      return (
        <svg
          className="svg__icon svg__checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
        </svg>
      );
    case 'checkmark-outline':
      return (
        <svg
          className="svg__icon svg__checkmark-outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
        </svg>
      );
    case 'cheveron-down':
      return (
        <svg
          className="svg__icon svg__cheveron-down"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      );
    case 'cheveron-left':
      return (
        <svg
          className="svg__icon svg__cheveron-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
        </svg>
      );
    case 'cheveron-right':
      return (
        <svg
          className="svg__icon svg__cheveron-right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
        </svg>
      );
    case 'cheveron-up':
      return (
        <svg
          className="svg__icon svg__cheveron-up"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
        </svg>
      );
    case 'cheveron-outline-down':
      return (
        <svg
          className="svg__icon svg__cheveron-outline-down"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-.7 10.54L5.75 9l1.41-1.41L10 10.4l2.83-2.82L14.24 9 10 13.24l-.7-.7z" />
        </svg>
      );
    case 'cheveron-outline-left':
      return (
        <svg
          className="svg__icon svg__cheveron-outline-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm8-10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zM7.46 9.3L11 5.75l1.41 1.41L9.6 10l2.82 2.83L11 14.24 6.76 10l.7-.7z" />
        </svg>
      );
    case 'cheveron-outline-right':
      return (
        <svg
          className="svg__icon svg__cheveron-outline-right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zM2 10a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm10.54.7L9 14.25l-1.41-1.41L10.4 10 7.6 7.17 9 5.76 13.24 10l-.7.7z" />
        </svg>
      );
    case 'cheveron-outline-up':
      return (
        <svg
          className="svg__icon svg__cheveron-outline-up"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm10 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm.7-10.54L14.25 11l-1.41 1.41L10 9.6l-2.83 2.8L5.76 11 10 6.76l.7.7z" />
        </svg>
      );
    case 'close':
      return (
        <svg
          className="svg__icon svg__close"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
        </svg>
      );
    case 'cog':
      return (
        <svg
          className="svg__icon svg__cog"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
      );
    case 'compose':
      return (
        <svg
          className="svg__icon svg__compose"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
        </svg>
      );
    case 'conversation':
      return (
        <svg
          className="svg__icon svg__conversation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M17 11v3l-3-3H8a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-1zm-3 2v2a2 2 0 0 1-2 2H6l-3 3v-3H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h2v3a4 4 0 0 0 4 4h6z" />
        </svg>
      );
    case 'currency-dollar':
      return (
        <svg
          className="svg__icon svg__currency-dollar"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z" />
        </svg>
      );
    case 'dashboard':
      return (
        <svg
          className="svg__icon svg__dashboard"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z" />
        </svg>
      );
    case 'download':
      return (
        <svg
          className="svg__icon svg__download"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
        </svg>
      );
    case 'edit-pencil':
      return (
        <svg
          className="svg__icon svg__edit-pencil"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
        </svg>
      );
    case 'envelope':
      return (
        <svg
          className="svg__icon svg__envelope"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" />
        </svg>
      );
    case 'filter':
      return (
        <svg
          className="svg__icon svg__filter"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M12 12l8-8V0H0v4l8 8v8l4-4v-4z" />
        </svg>
      );
    case 'gift':
      return (
        <svg
          className="svg__icon svg__gift"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M14.83 4H20v6h-1v10H1V10H0V4h5.17A3 3 0 0 1 10 .76 3 3 0 0 1 14.83 4zM8 10H3v8h5v-8zm4 0v8h5v-8h-5zM8 6H2v2h6V6zm4 0v2h6V6h-6zM8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
        </svg>
      );
    case 'inbox-full':
      return (
        <svg
          className="svg__icon svg__inbox-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M14 14h4V2H2v12h4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2zM0 2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4 2h12v2H4V4zm0 3h12v2H4V7zm0 3h12v2H4v-2z" />
        </svg>
      );
    case 'link':
      return (
        <svg
          className="svg__icon svg__link"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z" />
        </svg>
      );
    case 'menu':
      return (
        <svg
          className="svg__icon svg__menu"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      );
    case 'notifications':
      return (
        <svg
          className="svg__icon svg__notifications"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M4 8a6 6 0 0 1 4.03-5.67 2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8zm8 10a2 2 0 1 1-4 0h4z" />
        </svg>
      );
    case 'notifications-outline':
      return (
        <svg
          className="svg__icon svg__notifications-outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M6 8v7h8V8a4 4 0 1 0-8 0zm2.03-5.67a2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8a6 6 0 0 1 4.03-5.67zM12 18a2 2 0 1 1-4 0h4z" />
        </svg>
      );
    case 'search':
      return (
        <svg
          className="svg__icon svg__search"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
      );
    case 'send':
      return (
        <svg
          className="svg__icon svg__send"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
        </svg>
      );
    case 'star-full':
      return (
        <svg
          className="svg__icon svg__star-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
        </svg>
      );
    case 'user':
      return (
        <svg
          className="svg__icon svg__user"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z" />
        </svg>
      );
    case 'user-group':
      return (
        <svg
          className="svg__icon svg__user-group"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...props}
        >
          <path d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z" />
        </svg>
      );
    default:
      console.error('[SVG] Image not found.');
      return null;
  }
};

SVG.propTypes = {
  // Which SVG to display.
  image: PropTypes.string.isRequired,
};

export default SVG;
